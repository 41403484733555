import { Component, ElementRef, HostListener, OnDestroy } from '@angular/core';

import { StoryService } from './_core/state/story';
import { GlobalQuery, GlobalService } from './_core/state/global';
import { SessionService } from './_core/state/session/session.service';
import { Profile, SessionQuery } from './_core/state/session';
import { ChildrenOutletContexts, NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ORG_SETTINGS, STYLE_SETTINGS } from './_core/state/session/session.store';
import { environment } from '../environments/environment';
import { SelectDialogComponent } from './shared/components/select-dialog/select-dialog.component';
import { fade, routerTransitionFade } from './_core/utils/animations.utils';
import { AnalyticsService } from './_core/services/analytics.service';
import * as Sentry from '@sentry/angular-ivy';
import { combineLatest, distinctUntilChanged, first, filter, Subscription, take } from 'rxjs';
import { DisclaimerDialogComponent } from './shared/components/disclaimer-dialog/disclaimer-dialog.component';
// declare let gtag: Function;
import { News, NewsQuery, NewsService } from './_core/state/news';
import { NewsPopupDialogComponent } from './shared/components/news-popup-dialog/news-popup-dialog.component';
import { BusinessRole } from '../../../api/src/business/business-user.entity';
import { PublicBusiness } from '../../../api/src/business/business.entity';
import { PaymentMethodSlug } from '../../../api/src/paymentmethod/paymentmethod.entity';
import Hotjar from '@hotjar/browser';
import { LocalizationService } from './_core/services/localization.service';
import dayjs from 'dayjs';
import { GlobalSettings } from './_core/state/global/global.model';
import { LocaleCode } from './_core/models/locale';
import { locale } from 'dayjs';

import { OsCommonContext } from '@wppopen/core';
import { WppOpenService } from './_core/services/wpp-open.service';
import { Hierarchy } from '../../../api/src/_core/third-party/wpp-open/models';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	animations: [fade('fade', 500), routerTransitionFade('routerTransition', '500ms ease-in-out')]
})
export class AppComponent implements OnDestroy {
	public static LOGIN_REDIRECT_QUERY_PARAMETER = 'redirect';

	public loading = true;
	public customStyles: any;
	public customStylesLoaded = false;
	public user: Profile;
	private disclaimerSubs: Subscription;
	private globalSubs: Subscription;
	private hotjarEnabled: boolean = false;
	private paymentMethodsRequired: boolean;
	private disableTermsPopup: boolean;

	// Detect keypresses for setting admin mode
	@HostListener('document:keydown', ['$event'])
	onKeyPress(event: KeyboardEvent) {
		if (event.key === ';' && event.ctrlKey) {
			this.globalService.setAdminMode();
		}
	}

	constructor(
		private readonly dialog: MatDialog,
		private readonly globalService: GlobalService,
		private readonly globalQuery: GlobalQuery,
		private readonly storyService: StoryService,
		private readonly sessionService: SessionService,
		private readonly sessionQuery: SessionQuery,
		private readonly router: Router,
		private readonly title: Title,
		private contexts: ChildrenOutletContexts,
		private readonly analytics: AnalyticsService,
		private readonly newsService: NewsService,
		private readonly newsQuery: NewsQuery,
		private elementRef: ElementRef,
		private readonly localizationService: LocalizationService,
		private readonly wppOpenService: WppOpenService
	) {
		this._setCustomStyles();

		this.initializeApp();

		// this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(e => {
		// 	console.log('NavigationEnd', e);
		// });

		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				// window['fcWidget'].trackPage(event.urlAfterRedirects, document.title);

				// hotjar state change listener
				if (this.hotjarEnabled) {
					Hotjar.stateChange(event.url);
				}

				this.analytics.sendPageView(event.urlAfterRedirects, document.title);

				if (this.sessionQuery.getProfile()?.id) {
					// don't show popup if user is not logged in
					const popupNews = this.newsQuery.getPopupNewsForPath(this.sessionQuery.getProfile(), event.urlAfterRedirects);
					for (const news of popupNews) {
						this._showNewsPopup(news);
					}
				}
			}
		});

		// this.router.events.subscribe(event => {
		// 	window['Intercom']('update');
		// 	console.log('Page View', event);
		// 	if (event['NavigationEnd'].url) {
		// 		console.log(event['NavigationEnd'].url);
		// 		this.analytics.sendPageView(event['NavigationEnd'].url);
		// 	}
		// });
	}



	async ngOnInit() {
		// Likely in an iframe.
		// Attempt to login with WPP Open token.
		if(window.self !== window.top) {
			const token = await this.wppOpenService
				.getAccessToken()
				.catch(err => {
					console.error('Penpal child context error:', err);
					return null;
				});

			if(!token) {
				// No WPP Open token.
				return;
			}

			const workspaceScope = await this.wppOpenService
				.getWorkspaceScope()
				.catch(err => {
					console.error('Penpal child workspace scope error:', err);
					return null;
				});

			const context: OsCommonContext = await this.wppOpenService
				.getOsContext()
				.catch(err => {
					console.error('Penpal child context error:', err);
					return null;
				});

			this.sessionService
				.wppOpenLogin(
					token,
					environment.organizationId,
					workspaceScope?.workspaceId,
					workspaceScope?.scopeId,
					context?.project?.id,
					context?.project?.name,
					context?.hierarchy
				)
				.pipe(take(1))
				.subscribe((resp) => {
					if(resp.redirect) {
						this.router.navigate([resp?.redirect], {
							replaceUrl: true
						});
					}
				});
		}
	}

	ngOnDestroy() {
		this.disclaimerSubs?.unsubscribe();
		this.globalSubs?.unsubscribe();
	}

	private async initializeApp() {
		window['fcSettings'] = {
			onInit: () => {
				const locale = localStorage.getItem('localeCode');

				window['fcWidgetMessengerConfig'] = { ...window['fcWidgetMessengerConfig'], locale };
				window['fcWidget'].setConfig({
					locale,
					content: {
						welcomeMessage: $localize`:Fresh Chat Welcome Message@@FreshChatWelcomeMessage:Welcome to GTB Studio!`,
						welcomeSubMessage: $localize`:Fresh Chat Welcome SubMessage@@FreshChatWelcomeSubMessage:Have a question?`,
						placeholders: {
							search_field: $localize`:Fresh Chat Placeholder Search Field@@FreshChatPlaceholderSearchField:Search`,
							reply_field: $localize`:Fresh Chat Placeholder Reply Field@@FreshChatPlaceholderReplyField:Reply`,
							csat_reply: $localize`:Fresh Chat Placeholder Csat Reply@@FreshChatPlaceholderCsatReply:Add your comments here`
						},
						actions: {
							csat_yes: $localize`:Fresh Chat Action Csat Yes@@FreshChatActionCsatYes:Yes`,
							csat_no: $localize`:Fresh Chat Action Csat No@@FreshChatActionCsatNo:No`,
							push_notify_yes: $localize`:Fresh Action Chat Push Notify Yes@@FreshChatActionPushNotifyYes:Yes`,
							push_notify_no: $localize`:Fresh Chat Action Push Notify No@@FreshChatActionPushNotifyNo:No`,
							csat_submit: $localize`:Fresh Chat Action Csat Submit@@FreshChatActionCsatSubmit:Submit`
						},
						headers: {
							chat: $localize`:Fresh Chat Header Chat@@FreshChatHeaderChat:Send us a message!`,
							faq: $localize`:Fresh Chat Header FAQ@@FreshChatHeaderFAQ:FAQs`,
							faq_search_not_available: $localize`:Fresh Chat Header FAQ Search Not Available@@FreshChatHeaderFAQSearchNotAvailable:No articles were found for {{query}}`,
							faq_useful: $localize`:Fresh Chat Header FAQ Useful@@FreshChatHeaderFAQUsefull:Was this article helpful?`,
							faq_thankyou: $localize`:Fresh Chat Header FAQ Thank You@@FreshChatHeaderFAQThankYou:Thank you for your feedback`,
							faq_message_us: $localize`:Fresh Chat Header FAQ Message Us@@FreshChatHeaderFAQMessage Us:Message Us`,
							push_notification: $localize`:Fresh Chat Header Push Notification@@FreshChatHeaderPushNotification:Don't miss out on any replies! Allow push notifications?`,
							csat_question:
								'$localize`:Fresh Chat Header Csat Question@@FreshChatHeaderCsatQuestion:Did we address your concerns??`',
							csat_yes_question: $localize`:Fresh Chat Header Csat Yes Question@@FreshChatHeaderCsatYesQuestion:How would you rate this interaction?`,
							csat_no_question: $localize`:Fresh Chat Header Csat No Question@@FreshChatHeaderCsatNoQuestion:How could we have helped better?`,
							csat_thankyou: $localize`:Fresh Chat Header Csat Thank You@@FreshChatHeaderCsatThankYou:Thanks for the response`,
							csat_rate_here: $localize`:Fresh Chat Header Csat Rate Here@@FreshChatHeaderCsatRateHere:Submit your rating here`,
							channel_response: {
								offline: $localize`:Fresh Chat Header Channel Response Offline@@FreshChatHeaderChannelResponseOffline:We are currently away. Please leave us a message`,
								online: {
									minutes: {
										one: $localize`:Fresh Chat Header Channel Response Online Minutes One@@FreshChatHeaderChannelResponseOnlineMinutesOne:Currently replying in {!time!} minutes `,
										more: $localize`:Fresh Chat Header Channel Response Online Minutes More@@FreshChatHeaderChannelResponseOnlineMinutesMore:Typically replies in {!time!} minutes`
									},
									hours: {
										one: $localize`:Fresh Chat Header Channel Response Online Hours One@@FreshChatHeaderChannelResponseOnlineHoursOne:Currently replying in under an hour`,
										more: $localize`:Fresh Chat Header Channel Response Online Hours More@@FreshChatHeaderChannelResponseOnlineHoursMore:Typically replies in {!time!} hours`
									}
								}
							}
						}
					}
				});

				window['fcWidget'].hide();
			}
		};

		const settings = await this.loadOrgSettings().catch(err => {
			console.log(err);
			localStorage.removeItem(ORG_SETTINGS);
			return null;
		});

		if (!settings) {
			console.error('Configuration error. Unable to load or parse API_MAP.');
		}

		// Don't try to get the user if we're on the login or signup page.
		// Any url that needs access to unauthenticated users should be added here.
		if (
			window.location.pathname === '/login' ||
			window.location.pathname === '/signup' ||
			window.location.pathname.startsWith('/share/')
		) {

			this.hideFreshWorksWidgets();

			this.loadGlobalSettings();
			return;
		}

		this.sessionService.getUserStatus(this.sessionQuery.getToken()).subscribe(
			response => {
				this.user = response.profile;
				this.newsService
					.get()
					.pipe(first())
					.subscribe(() => this._handleCustomConditionNews(response.profile)); // load news

				this.globalSubs = this.globalQuery.copy$.subscribe(settings => {
					if (settings.enableHotjar && !this.hotjarEnabled) {
						this.hotjarEnabled = true;
						Hotjar.init(Number(environment.hotjarSiteId), Number(environment.hotjarVersion), {
							nonce: 'rAnDoM' // to prevent cors errors
						});
						Hotjar.identify(this.user.id, {
							name: this.user.fullName,
							email: this.user.email
						});
					}

					if (settings && !!settings.enableIntercom) {
						this.startFreshworksWidget(this.user, settings);
					}
				});

				Sentry.setUser({ email: response.profile.email });
				this.loadGlobalSettings();
				this.analytics.setSessionVars(this.user.id);
				this.analytics.setDimension('userId', this.user.id);
				this.validateBusinessSetup(response);

				if (environment.production) {
					Sentry.getCurrentHub()
						.getClient()
						.addIntegration(
							new Sentry.Replay({
								maskAllText: false,
								blockAllMedia: false
							})
						);
				}

				this.globalQuery
					.select('settings')
					.pipe(filter(settings => !!settings))
					.subscribe(settings => {
						if (!settings?.freeTrialEnabled) return;
						let existsActiveSubscription: boolean = false;
						let redirectToBusinessId: string = null;
						for (let business of this.user.businesses) {
							if (business.hasActiveSubscription) {
								existsActiveSubscription = true;
								continue;
							}
							if ([BusinessRole.Owner, BusinessRole.Finance].includes(business.role)) {
								redirectToBusinessId = business.id;
							}
						}
						if (!existsActiveSubscription && settings.paymentMethodsRequired) {
							if (redirectToBusinessId) {
								this.router.navigate(['setup-subscription', redirectToBusinessId]);
							} else {
								this.router.navigate(['dashboard']);
								this.globalService.triggerErrorMessage(
									undefined,
									$localize`:Error Message@@existsActiveSubscriptionErrorMessageText:Please contact your business owner or financial user to subscribe`
								);
							}
						}
					});
			},
			() => {
				// Invalid User
				const queryParams =
					window.location.pathname === '/'
						? undefined
						: { [AppComponent.LOGIN_REDIRECT_QUERY_PARAMETER]: window.location.pathname };
				this.router.navigate(['/login'], {
					queryParams,
					replaceUrl: true
				});

				this.loadGlobalSettings();
			}
		);
	}

	validateBusinessSetup(response: any) {
		if (window.location.pathname.indexOf('validate-subscription/') <= -1) {
			if (response?.redirect && response?.redirect !== '') {
				this.router.navigate([response?.redirect], {
					replaceUrl: true
				});
			}
		}
	}

	validateBusinessSubscription() {
		let noSubscriptionBusinesses = this.user?.businesses.filter(business => {
			return !business.hasActiveSubscription;
		});

		if (noSubscriptionBusinesses.length > 0) {
			this.router.navigate(['/setup-subscription/' + noSubscriptionBusinesses[0].id], {
				replaceUrl: true,
				state: {
					previousState: window.location.pathname
				}
			});
		}
	}

	loadGlobalSettings() {
		this.globalService.get().subscribe((settings: GlobalSettings) => {
			this.title.setTitle(settings.siteTitle);

			// Set the favicon
			let favIcon: HTMLLinkElement = document.querySelector('#appIcon') as HTMLLinkElement;
			favIcon.href = settings.siteFaviconUrl || '';

			// Set custom styles
			this._setCustomStyles(settings);

			// Add body scripts to the DOM.
			if (settings.bodyScripts) {
				const parser = new DOMParser();
				const doc = parser.parseFromString(settings.bodyScripts, 'text/html');
				const scripts = doc.querySelectorAll('script');

				scripts.forEach(scriptElement => {
					if (scriptElement.src) {
						// For external scripts
						const script = document.createElement('script');
						script.src = scriptElement.getAttribute('src');
						script.async = true;
						document.body.appendChild(script);
					} else {
						// For inline scripts
						const script = document.createElement('script');
						script.textContent = scriptElement.textContent;
						document.body.appendChild(script);
					}
				});
			}

			this.paymentMethodsRequired = settings.paymentMethodsRequired;
			this.disableTermsPopup = settings.disableTermsPopup;

			this.loading = false;

			// // Sentry Tags
			// Sentry.setTag('organization', settings.organizationName);
			this._handleTerms();
		});
	}

	private _handleTerms() {
		if (this.disableTermsPopup) return;

		this.disclaimerSubs = combineLatest([
			this.sessionQuery.profile$,
			this.sessionQuery.isLoggedIn$,
			this.globalQuery.authenticatedSettings$
		])
			.pipe(
				filter(([profile, isLoggedIn, settings]) => isLoggedIn && profile?.['acceptedTerms'] !== undefined && !!settings.terms),
				distinctUntilChanged(([profile, isLoggedIn, settings]) => !!profile?.['acceptedTerms'] && !!settings.terms),
				first()
			)
			.subscribe(([profile, isLoggedIn, settings]) => {
				if (!isLoggedIn || !profile || !settings?.terms) {
					return;
				}

				const allowByAcceptedTermsDate =
					profile?.['acceptedTermsDate'] === null || dayjs(profile['acceptedTermsDate']) < dayjs(new Date()).subtract(1, 'year');
				if (!profile?.['acceptedTerms'] || allowByAcceptedTermsDate) {
					const dialogRef = this.dialog.open(DisclaimerDialogComponent, {
						data: {
							title: 'Terms and Conditions',
							message: settings.terms,
							requireRead: true
						},
						disableClose: true,
						panelClass: ['background-color-alternative', 'max-width-lg']
					});

					dialogRef
						.afterClosed()
						.pipe(first())
						.subscribe(result => {
							if (!!result) {
								this.sessionService.acceptTerms().subscribe();
							}
						});
				}
			});
	}

	private async loadOrgSettings() {
		return new Promise((resolve, reject) => {
			if (!environment.exclusive) {
				const savedOrg = localStorage.getItem(ORG_SETTINGS);

				if (savedOrg) {
					try {
						let orgData = JSON.parse(savedOrg);
						environment.apiUrl = orgData.endpoint;
						environment.cmsUrl = orgData.cmsUrl;
						environment.cmsToken = orgData.cmsToken;
						environment.organizationId = orgData.organizationId;
						environment.production = orgData.production || environment.production;
						environment.currencySymbol = orgData.currencySymbol || environment.currencySymbol;
						environment.currencyCode = orgData.currencyCode || environment.currencyCode;
						environment.locale = orgData.locale || environment.locale;
						environment.cloudinaryCloudName = orgData.cloudinaryCloudName || environment.cloudinaryCloudName;
						environment.taskerEngineUrl = orgData.taskerEngineUrl || environment.taskerEngineUrl;
						environment.briaModifyIframeId = orgData.briaModifyIframeId || environment.briaModifyIframeId;
						resolve(true);
					} catch (err) {
						reject(`Couldn't parse org settings.`);
						return;
					}
				} else {
					const dialogRef = this.dialog.open(SelectDialogComponent, {
						data: {
							title: 'Select an Organization',
							options: environment.apiSettings?.reduce((acc: any, cur: any) => {
								acc[cur.name] = cur;
								return acc;
							}, {})
						}
					});

					dialogRef.afterClosed().subscribe(result => {
						localStorage.setItem(ORG_SETTINGS, JSON.stringify(result));
						environment.apiUrl = result.endpoint;
						environment.cmsUrl = result.cmsUrl;
						environment.cmsToken = result.cmsToken;
						environment.organizationId = result.organizationId;
						environment.production = result.production || environment.production;
						environment.currencySymbol = result.currencySymbol || environment.currencySymbol;
						environment.currencyCode = result.currencyCode || environment.currencyCode;
						environment.locale = result.locale || environment.locale;
						environment.cloudinaryCloudName = result.cloudinaryCloudName || environment.cloudinaryCloudName;
						environment.taskerEngineUrl = result.taskerEngineUrl || environment.taskerEngineUrl;
						environment.briaModifyIframeId = result.briaModifyIframeId || environment.briaModifyIframeId;
						resolve(true);
					});
				}
			} else {
				resolve(true);
			}
		});
	}

	private _setCustomStyles(settings?: GlobalSettings) {
		// cleanup
		if (settings && !settings.customStyles) {
			localStorage.removeItem(STYLE_SETTINGS);
			document.getElementById(STYLE_SETTINGS)?.remove();
			this.customStylesLoaded = true;
			return;
		}

		const customStyles = settings?.customStyles || localStorage.getItem(STYLE_SETTINGS);

		if (customStyles) {
			const style = document.createElement('style');
			style.id = STYLE_SETTINGS;
			style.textContent = customStyles;
			document.head.appendChild(style);
			localStorage.setItem(STYLE_SETTINGS, customStyles);
		}

		this.customStylesLoaded = !!customStyles || !!settings;
	}

	getAnimationSettings() {
		return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animationKey'];
	}

	private hideFreshWorksWidgets() {

		setTimeout(() => {
			if (window['fcWidget']) {
				window['fcWidget'].hide();
			}

			if (window['FreshworksWidget']) {
				window['FreshworksWidget']('hide');
			}
		}, 1000);
	}

	private showFreshWorksContainer() {
		const freshworksContainer = document.getElementById('freshworks-container');
		freshworksContainer?.classList.add('visible');
	}

	startFeedbackWidget(user: Profile, settings) {
		const locale = localStorage.getItem('localeCode');

		console.log('settings', settings);
		window['FreshworksWidget']('setLabels', {
			[locale]: {
				banner: settings.siteTitle + ' ' + $localize`:Fresh Desk Contact Form Banner@@FreshDeskBanner: Support and Feedback`,
				launcher: $localize`:Fresh Desk Contact Form Title@@FreshDeskLauncherTitle:Feedback`,
				contact_form: {
					title: $localize`:Fresh Desk Contact Form Title@@FreshDeskContactFormTitle:Contact Us`,
					name: $localize`:Fresh Desk Contact Form Name@@FreshDeskContactFormName:Name`,
					email: $localize`:Fresh Desk Contact Form Email@@FreshDeskContactFormEmail:Email`,
					description: $localize`:Fresh Desk Contact Form Description@@FreshDeskContactFormDescription:Description Here`,
					submit: $localize`:Fresh Desk Contact Form Submit@@FreshDeskContactFormSubmit:Submit`,
					confirmation: $localize`:Fresh Desk Contact Form Confirmation@@FreshDeskContactFormConfirmation:Thank You! We will get back to you shortly.`,
					close: $localize`:Fresh Desk Contact Form Close@@FreshDeskContactFormClose:Exit.`
				}
			}
		});

		window['FreshworksWidget']('show');
	}

	startFreshChat(user: Profile, settings) {
		const locale = localStorage.getItem('localeCode');
		window['fcWidget']['user'].setLocale(locale);

		if (user) {
			window['fcWidget']['user'].get(function (resp) {
				const status = resp && resp.status;
				const data = resp && resp.data;

				//if (status !== 200) {
				window['fcWidget']['user'].setProperties({
					email: user?.email, // user's email address
					firstName: user?.settings?.fullName?.split(' ').slice(0, -1).join(' '), // user's first name
					lastName: user?.settings?.fullName?.split(' ').slice(-1).join(' '), // user's last name
					phone: user?.businesses[0]?.phone, // user's phone number
					cf_dealership_name: user?.businesses[0]?.name,
					cf_dealership_id: user?.businesses[0]?.id,
					cf_stripe_account_status: user?.businesses[0]?.hasActiveSubscription,
					cf_stripe_cus_id: user?.businesses[0]?.stripeCustomerId,
					externalId: user?.email // user's unique identifier
				});
				window['fcWidget']['user'].setLocale(locale);

				window['fcWidget'].on('user:created', function (resp) {
					var status = resp && resp.status,
						data = resp && resp.data;
					if (status === 200) {
						if (data.restoreId) {
							// Update restoreId in your database
						}
					}
				});
				//}
			});
			console.log('start FreshDesk');

			window['fcWidget'].show();
		}
	}

	startFreshworksWidget(user: Profile, settings) {
		setTimeout(() => {
			if (window['fcWidget']) {
				this.startFreshChat(user, settings);
			}

			if (window['FreshworksWidget']) {
				this.startFeedbackWidget(user, settings);
			}

			this.showFreshWorksContainer();
		}, 3000);
	}

	private _handleCustomConditionNews(profile: Profile) {
		const customConditionNews = this.newsQuery.getPopupNewsForCustomCondition(this.sessionQuery.getProfile());
		customConditionNews.forEach(news => {
			if (news.popupCustomCondition === 'businessExternalPaymentMethodSetup' && this.paymentMethodsRequired) {
				const relevantBusinesses =
					profile?.businesses?.filter(b => [BusinessRole.Owner, BusinessRole.Finance].includes(b.role)) || [];
				const freeTrialBusinesses = relevantBusinesses.filter(
					b => !b.hasActiveSubscription && b.freeTrialExpires && new Date() < new Date(b.freeTrialExpires)
				);

				if (freeTrialBusinesses?.length) {
					// if more than one business and have free trial
					const oldestBusiness = freeTrialBusinesses.sort(
						(a, b) => new Date(a.created).getTime() - new Date(b.created).getTime()
					)[0];
					if (
						oldestBusiness.hasActiveSubscription === false &&
						(news.popupButtonsLeft.find(b => b.action === 'openPaymentMethodSetup') ||
							news.popupButtonsRight.find(b => b.action === 'openPaymentMethodSetup'))
					) {
						return;
					}
					this._showNewsPopup(news, oldestBusiness);
					return;
				}

				if (relevantBusinesses.length === 1 && relevantBusinesses[0]?.paymentMethod?.slug !== PaymentMethodSlug.PartsNumber) {
					// if only one business and it has CC payment method
					this._showNewsPopup(news, relevantBusinesses[0]);
					return;
				}

				if (relevantBusinesses.length > 1) {
					if (
						relevantBusinesses.filter(b => b.hasActiveSubscription && b.paymentMethod?.slug !== PaymentMethodSlug.PartsNumber)
							.length === relevantBusinesses.length
					) {
						// if more than one business and all have CC payment method
						const popupQueue = relevantBusinesses
							.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime())
							.map(business => ({ news, business }));
						this.newsQuery.setPopupQueue(popupQueue);
						const { news: oldestNews, business: oldestBusiness } = this.newsQuery.getNextMessageFromQueue() || {};
						this._showNewsPopup(oldestNews, oldestBusiness);
						return;
					}
				}
			}
		});
	}

	private _showNewsPopup(news: News, business?: PublicBusiness) {
		if (!news) {
			return;
		}
		const backgroundClass = news.popupBackgroundColor ? `background-color-${news.popupBackgroundColor}` : 'background-color';
		const sizeClass = `max-width-${news.popupSize || 'lg'}`;

		this.dialog.open(NewsPopupDialogComponent, {
			data: { news, business },
			panelClass: [backgroundClass, sizeClass],
			disableClose: true
		});
		// don't show multiple popups
		//.afterClosed()
		//.pipe(first())
		//.subscribe(result => {
		//	if (!result || result === 'close') {
		//		const { news, business } = this.newsQuery.getNextMessageFromQueue() || {};
		//		this._showNewsPopup(news, business);
		//	}
		//});
	}
}
